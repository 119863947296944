<template>
  <page-view>
    <div class="base_wrapper">
      <div
        class="title_base"
        :class="deviceType == 'desktop' ? 'mobile_width' : ''"
      >
        基本信息
      </div>
      <div class="user_info">
        <div class="left" :class="deviceType == 'mobile' ? 'left2' : ''">
          <div>用户名：{{ user.nickname }}</div>
          <div>用户ID：{{ user.id }}</div>
        </div>

        <a-upload
          name="avatar"
          list-type="picture"
          class="avatar-uploader"
          :show-upload-list="false"
          :customRequest="handleChange"
          accept="image/gif, image/png, image/jpeg, image/webp"
        >
          <div class="right">
            <img v-if="avatarUrl" :src="avatarUrl" alt class="avatar" />
            <img v-else src="@/assets/icon／用户.png" alt />

            <span>更换头像</span>
          </div>
        </a-upload>
      </div>
      <div class="safty_setting">安全设置</div>
      <div class="safty_setting_item">
        <span>账号密码</span>
        <div
          class="setting_action"
          :class="deviceType == 'mobile' ? 'mobile_width' : ''"
        >
          <span>点击右侧修改账号密码</span>
          <div class="action_btn" @click="showSettingInput('password')">
            <span>点击修改</span>
            <a-icon v-if="!showPassword" type="caret-down" class="icon" />
            <a-icon v-else type="caret-up" class="icon" />
          </div>
        </div>
        <PassWord
          v-show="showPassword"
          ref="password"
          @passwordUpdateSuccess="passwordUpdateSuccess"
        />
      </div>
      <div class="safty_setting_item" :class="!alipayAccount ? 'action2' : ''">
        <span>手机号码</span>
        <div
          class="setting_action"
          :class="deviceType == 'mobile' ? 'mobile_width' : ''"
        >
          <span v-if="user.mobile"
            >已绑定手机：{{
              user.mobile.substring(0, 3) + "****" + user.mobile.substring(7)
            }}</span
          >
          <div class="action_btn" @click="showSettingInput('mobile')">
            <span>点击修改</span>
            <a-icon v-if="!showMobile" type="caret-down" class="icon" />
            <a-icon v-else type="caret-up" class="icon" />
          </div>
        </div>
        <Mobile
          v-show="showMobile"
          ref="mobile"
          @mobileUpdateSuccess="mobileUpdateSuccess"
        />
      </div>
      <div
        class="safty_setting_item"
        v-if="alipayAccount && alipayName"
        :class="showAlipay == false ? 'last_action' : ''"
      >
        <span>提现支付宝账号</span>
        <div
          class="setting_action"
          :class="deviceType == 'mobile' ? 'mobile_width' : ''"
        >
          <span
            >已绑定账号：{{
              alipayAccount.substring(0, 3) +
                "****" +
                alipayAccount.substring(7)
            }}（{{
              alipayName.substring(0, 1) + "*" + alipayName.substring(2)
            }}）</span
          >

          <div class="action_btn" @click="showSettingInput('alipay')">
            <span>点击修改</span>
            <a-icon v-if="!showAlipay" type="caret-down" class="icon" />
            <a-icon v-else type="caret-up" class="icon" />
          </div>
        </div>
        <Alipay
          v-show="showAlipay"
          ref="alipay"
          @alipayUpdateSuccess="alipayUpdateSuccess"
        />
      </div>
    </div>
  </page-view>
</template>

<script>
import { mapGetters } from "vuex";
import { accountGet, accountUpdate, filePreUpload, uploadOSS } from "@api";
import { uuid } from "@/utils/util";
import { PageView } from "@/layouts";
import PassWord from "@comp/Account/password";
import Mobile from "@comp/Account/mobile";
import Alipay from "@comp/Account/alipay";
import { mixin, mixinDevice } from "@/utils/mixin";
export default {
  mixins: [mixin, mixinDevice],
  components: {
    PageView,
    PassWord,
    Mobile,
    Alipay
  },
  watch: {
    device: {
      handler(newval, oldval) {
        this.deviceType = newval;
      },
      deep: true,
      immediate: true
    }
  },
  data() {
    return {
      avatarUrl: "",
      alipayAccount: "",
      alipayName: "",
      user: {},
      showPassword: false,
      showMobile: false,
      showAlipay: false,
      deviceType: ""
    };
  },
  mounted() {
    this.user = this.userInfo();
    accountGet().then(res => {
      this.alipayAccount = res.data.alipayAccount;
      this.alipayName = res.data.alipayName;
      this.avatarUrl = res.data.avatarUrl;
    });
  },

  methods: {
    passwordUpdateSuccess() {
      this.showPassword = false;
    },
    mobileUpdateSuccess() {
      this.showMobile = false;
    },
    alipayUpdateSuccess(data) {
      this.showAlipay = false;
      this.alipayAccount = data.alipayAccount;
      this.alipayName = data.alipayName;
    },
    handleChange(fileData) {
      // TODO 1 上传到OSS
      filePreUpload()
        .then(response => {
          const data = response.data;
          const url = data.url;
          const prefix = data.key;
          delete data.url;
          delete data.key;
          const array = fileData.file.name.replace(" ", "").split("."); // 把文件名数组化
          let suffix = ""; // 后缀
          if (array.length > 1) {
            suffix = `.${array.pop().toLowerCase()}`;
          }
          const formData = new FormData();
          Object.keys(data).forEach(it => {
            formData.append(it, data[it]);
          });
          const key = `${prefix}/${uuid()}${suffix}`; // 图片访问路径
          formData.append("key", key);
          formData.append("file", fileData.file);
          uploadOSS(url, formData)
            .then(response => {
              // 上传成功，设置图片访问路径
              let avatarUrl = "http://cache.wodh.cn/" + key;
              accountUpdate({
                avatarUrl
              }).then(res3 => {
                this.$message.success("修改成功");
                accountGet().then(res4 => {
                  this.avatarUrl = res4.data.avatarUrl;
                });
              });
              return fileData.onSuccess(); // 上传成功了
            })
            .catch(error => {
              this.$message.error("上传异常." + error);
              return fileData.onError(); // 拒绝上传操作
            });
        })
        .catch(error => {
          this.$message.error("授权异常." + error);
          return fileData.onError(); // 拒绝上传操作
        });
    },
    ...mapGetters(["userInfo"]),
    showSettingInput(type) {
      if (type == "password") {
        this.$refs.password.removeError();
        this.showPassword = !this.showPassword;
      }
      if (type == "mobile") {
        this.$refs.mobile.removeError();
        this.showMobile = !this.showMobile;
      }
      if (type == "alipay") {
        this.$refs.alipay.removeError();
        this.showAlipay = !this.showAlipay;
      }
    }
  }
};
</script>
<style lang="less" scoped>
.base_wrapper {
  box-shadow: 0px 2px 20px rgba(15, 41, 131, 0.1);
  padding: 40px 0 24px 64px;
  background-color: #fff;
  font-family: PingFang SC;
  .title_base,
  .safty_setting {
    font-size: 24px;
    padding-bottom: 16px;
    color: rgba(0, 0, 0, 0.85);
    border-bottom: 1px solid #f0f0f0;
  }
  .user_info {
    margin: 24px 0;
    color: rgba(0, 0, 0, 0.65);
    display: flex;
    .left {
      margin-right: 200px;

      div {
        margin-bottom: 24px;
      }
    }

    .right {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      left: 0;
      .avatar {
        width: 96px;
        height: 96px;
        border-radius: 50%;
        margin-bottom: 8px;
        object-fit: cover;
      }
      span {
        cursor: pointer;
        color: #1890ff;
      }
    }
    .left2 {
      margin-right: 10px;
    }
  }

  .safty_setting_item {
    padding-top: 24px;
    border-bottom: 1px solid #f0f0f0;

    span {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.85);
      margin-bottom: 8px;
    }
    .mobile_width {
      width: 302px !important;
    }
    .setting_action {
      display: flex;
      width: 590px;
      justify-content: space-between;
      margin-bottom: 24px;
      span {
        color: rgba(0, 0, 0, 0.45);
        font-size: 14px;
        margin-bottom: 0;
      }
      .action_btn {
        span {
          cursor: pointer;
          color: #1890ff;
          margin-right: 8px;
        }
        .icon {
          color: rgba(0, 0, 0, 0.45);
          font-size: 8px;
        }
      }
    }
    &:last-child {
      border-bottom: 0;
    }
  }

  .last_action {
    padding-bottom: 228px;
  }
  .action2 {
    padding-bottom: 228px;
    border-bottom: 0;
  }
  /deep/ .ant-upload-picture-card-wrapper {
    width: 100px;
  }
  /deep/.avatar-uploader > .ant-upload {
    width: 96px;
    height: 96px;
    border-radius: 50%;
    position: relative;
    border: 0;
  }
}
</style>
