<template>
  <a-form :form="form" :hideRequiredMark="true">
    <a-form-item
      label="支付宝账号"
      :labelCol="{ lg: { span: 2 }, sm: { span: 2 } }"
      :wrapperCol="{ lg: { span: 12 }, sm: { span: 12 } }"
    >
      <a-input
        class="input"
        type="text"
        autocomplete="false"
        placeholder="请输入支付宝账号"
        v-decorator="[
          'alipay',
          {
            rules: [
              {
                required: true,
                pattern: /^(?:1[3-9]\d{9}|[a-zA-Z\d._-]*\@[a-zA-Z\d.-]{1,10}\.[a-zA-Z\d]{1,20})$/,
                message: '请输入正确的支付宝账号'
              }
            ],
            validateTrigger: 'change'
          }
        ]"
      ></a-input>
    </a-form-item>

    <a-form-item
      label="真实姓名"
      :labelCol="{ lg: { span: 2 }, sm: { span: 2 } }"
      :wrapperCol="{ lg: { span: 12 }, sm: { span: 12 } }"
    >
      <a-input
        class="input"
        type="text"
        autocomplete="false"
        placeholder="真实姓名"
        v-decorator="[
          'realName',
          {
            rules: [{ required: true, message: '请输入真实姓名' }],
            validateTrigger: 'change'
          }
        ]"
      ></a-input>
    </a-form-item>
    <a-form-item class="ok_btn">
      <a-button
        type="primary"
        htmlType="submit"
        @click.stop.prevent="handleSubmit"
        >确认修改</a-button
      >
    </a-form-item>
  </a-form>
</template>

<script>
import { sipSms, accountUpdate, accountGet } from '@api';

export default {
  data() {
    return {
      form: this.$form.createForm(this)
    };
  },

  methods: {
    handleSubmit() {
      const {
        form: { validateFields },
        $router,
        changeMobile
      } = this;
      validateFields((err, values) => {
        //alipayAccount  支付宝登录名
        // alipayName  支付宝真实姓名
        if (!err) {
          const data = {
            alipayAccount: values.alipay,
            alipayName: values.realName
          };
          accountUpdate(data).then((res) => {
            if (res.success) {
              this.$message.success('修改成功');
              this.form.resetFields();
              accountGet().then((res) => {
                // 子传父
                if (res.success) {
                  this.$emit('alipayUpdateSuccess', res.data);
                } else {
                  this.$message.error(res.msg);
                }
              });
            } else {
              this.$message.error(res.msg);
            }
          });
        }
      });
    },

    removeError() {
      this.form.resetFields();
    }
  }
};
</script>

<style lang="less" scoped>
.input {
  width: 280px;
  height: 32px;
}
.captcha_input {
  width: 179px;
  height: 32px;
}
.captcha_btn {
  width: 102px;
  height: 32px;
}
.ok_btn {
  margin-left: 130px;
  margin-bottom: 24px;
}
</style>
