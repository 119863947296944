<template>
  <a-form :form="form" :hideRequiredMark="true">
    <a-form-item
      label="原手机号码"
      :labelCol="{ lg: { span: 2 }, sm: { span: 2 } }"
      :wrapperCol="{ lg: { span: 8 }, sm: { span: 8 } }"
    >
      <a-input
        class="input"
        type="text"
        autocomplete="false"
        placeholder="原手机号码"
        v-decorator="[
          'oldMobile',
          {
            rules: [
              {
                required: true,
                pattern: /^1[3456789]\d{9}$/,
                message: '请输入正确的手机号',
              },
            ],
            validateTrigger: 'change',
          },
        ]"
      ></a-input>
    </a-form-item>

    <a-form-item
      label="验证码"
      :labelCol="{ lg: { span: 2 }, sm: { span: 2 } }"
      :wrapperCol="{ lg: { span: 12 }, sm: { span: 12 } }"
    >
      <a-input-group compact>
        <a-input
          class="captcha_input"
          type="text"
          autocomplete="false"
          placeholder="手机验证码"
          v-decorator="[
            'oldMobileCaptcha',
            {
              rules: [{ required: true, message: '请输入验证码' }],
              validateTrigger: 'blur',
            },
          ]"
        ></a-input>
        <a-button
          class="captcha_btn"
          tabindex="-1"
          :disabled="stateOld.smsSendBtn"
          @click.stop.prevent="getOldMobileCaptcha"
          v-text="
            (!stateOld.smsSendBtn && '获取验证码') || stateOld.time + ' s'
          "
        ></a-button>
      </a-input-group>
    </a-form-item>
    <a-form-item
      label="新手机号"
      :labelCol="{ lg: { span: 2 }, sm: { span: 2 } }"
      :wrapperCol="{ lg: { span: 12 }, sm: { span: 12 } }"
    >
      <a-input
        class="input"
        autocomplete="false"
        placeholder="新手机号"
        v-decorator="[
          'newMobile',
          {
            rules: [
              {
                required: true,
                pattern: /^1[3456789]\d{9}$/,
                message: '请输入正确的手机号',
              },
            ],
            validateTrigger: 'change',
          },
        ]"
      ></a-input>
    </a-form-item>

    <a-form-item
      label="验证码"
      :labelCol="{ lg: { span: 2 }, sm: { span: 2 } }"
      :wrapperCol="{ lg: { span: 12 }, sm: { span: 12 } }"
    >
      <a-input-group compact>
        <a-input
          class="captcha_input"
          type="text"
          autocomplete="false"
          placeholder="手机验证码"
          v-decorator="[
            'newMobileCaptcha',
            {
              rules: [{ required: true, message: '请输入验证码' }],
              validateTrigger: 'blur',
            },
          ]"
        ></a-input>
        <a-button
          class="captcha_btn"
          tabindex="-1"
          :disabled="stateNew.smsSendBtn"
          @click.stop.prevent="getNewMobileCaptcha"
          v-text="
            (!stateNew.smsSendBtn && '获取验证码') || stateNew.time + ' s'
          "
        ></a-button>
      </a-input-group>
    </a-form-item>

    <a-form-item class="ok_btn">
      <a-button
        type="primary"
        htmlType="submit"
        @click.stop.prevent="handleSubmit"
        >确认修改</a-button
      >
    </a-form-item>
  </a-form>
</template>

<script>
import { sipUpdateMobile, sipSms } from "@api";

export default {
  data() {
    return {
      form: this.$form.createForm(this),
      stateOld: {
        time: 60,
        smsSendBtn: false,
        passwordLevel: 0,
        passwordLevelChecked: false,
        percent: 10,
        progressColor: "#FF0000",
      },
      stateNew: {
        time: 60,
        smsSendBtn: false,
        passwordLevel: 0,
        passwordLevelChecked: false,
        percent: 10,
        progressColor: "#FF0000",
      },
    };
  },
  methods: {
    getOldMobileCaptcha(e) {
      e.preventDefault();
      const {
        form: { validateFields },
        stateOld,
      } = this;
      validateFields(["oldMobile"], { force: true }, (err, values) => {
        if (!err) {
          values.mobile = values.oldMobile;
          stateOld.smsSendBtn = true;
          const interval = window.setInterval(() => {
            if (stateOld.time-- <= 0) {
              stateOld.time = 60;
              stateOld.smsSendBtn = false;
              window.clearInterval(interval);
            }
          }, 1000);
          const hide = this.$message.loading("验证码发送中..", 0);
          sipSms(values.mobile, 2)
            .then((res) => {
              if (res.success) {
                setTimeout(hide, 2500);
                this.$notification["success"]({
                  message: "提示",
                  description: "验证码获取成功",
                  duration: 8,
                });
              } else {
                setTimeout(hide, 2500);
                this.$notification["error"]({
                  message: "错误",
                  description: res.msg,
                  duration: 4,
                });
              }
            })
            .catch((err) => {
              setTimeout(hide, 1);
              clearInterval(interval);
              stateOld.time = 60;
              stateOld.smsSendBtn = false;
              this.requestFailed(err);
            });
        }
      });
    },
    getNewMobileCaptcha(e) {
      e.preventDefault();
      const {
        form: { validateFields },
        stateNew,
      } = this;
      validateFields(["newMobile"], { force: true }, (err, values) => {
        if (!err) {
          values.mobile = values.newMobile;
          stateNew.smsSendBtn = true;
          const interval = window.setInterval(() => {
            if (stateNew.time-- <= 0) {
              stateNew.time = 60;
              stateNew.smsSendBtn = false;
              window.clearInterval(interval);
            }
          }, 1000);
          const hide = this.$message.loading("验证码发送中..", 0);
          sipSms(values.mobile, 1)
            .then((res) => {
              if (res.success) {
                setTimeout(hide, 2500);
                this.$notification["success"]({
                  message: "提示",
                  description: "验证码获取成功",
                  duration: 8,
                });
              } else {
                setTimeout(hide, 2500);
                this.$notification["error"]({
                  message: "错误",
                  description: res.msg,
                  duration: 4,
                });
              }
            })
            .catch((err) => {
              setTimeout(hide, 1);
              clearInterval(interval);
              stateNew.time = 60;
              stateNew.smsSendBtn = false;
              this.requestFailed(err);
            });
        }
      });
    },
    handleSubmit() {
      const {
        form: { validateFields },
        $router,
        changeMobile,
      } = this;
      validateFields((err, values) => {
        if (!err) {
          sipUpdateMobile({
            code: values.oldMobileCaptcha,
            mobile: values.oldMobile,
            type: 3,
          }).then((result) => {
            if (result.success) {
              sipUpdateMobile({
                code: values.newMobileCaptcha,
                mobile: values.newMobile,
                type: 2,
              }).then((res) => {
                if (res.success) {
                  this.$message.success("修改成功,重新登录生效");
                  this.$emit("mobileUpdateSuccess");
                } else {
                  this.$message.error(res.msg);
                }
              });
            } else {
              this.$message.error(result.msg);
            }
          });
        }
      });
    },
    removeError() {
      this.form.resetFields();
    },
  },
};
</script>

<style lang="less" scoped>
.input {
  width: 280px;
  height: 32px;
}
.captcha_input {
  width: 179px;
  height: 32px;
}
.captcha_btn {
  width: 102px;
  height: 32px;
}
.ok_btn {
  margin-left: 130px;
}
</style>
