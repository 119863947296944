<template>
  <a-form :form="form" :hideRequiredMark="true">
    <a-form-item
      label="原密码"
      :labelCol="{lg: {span: 2}, sm: {span:2}}"
      :wrapperCol="{lg: {span: 8}, sm: {span:8} }"
    >
      <a-input
        class="input"
        type="password"
        autocomplete="false"
        placeholder="原密码"
        v-decorator="['orignPassword', {rules: [{ required: true, message: '请输入原密码'}], validateTrigger: ['change', 'blur']}]"
      ></a-input>
    </a-form-item>
    <a-form-item
      label="新密码"
      :labelCol="{lg: {span: 2}, sm: {span:2}}"
      :wrapperCol="{lg: {span: 8}, sm: {span:8} }"
    >
      <a-input-password
        class="input"
        type="password"
        autocomplete="false"
        placeholder="至少6位密码，区分大小写"
        v-decorator="['password', {rules: [{ required: true, message: '至少6位密码，区分大小写'}, { validator: this.handlePasswordLevel }], validateTrigger: ['change', 'blur']}]"
      ></a-input-password>
    </a-form-item>

    <a-form-item
      label="确认密码"
      :labelCol="{lg: {span: 2}, sm: {span:2}}"
      :wrapperCol="{lg: {span: 8}, sm: {span:8} }"
    >
      <a-input-password
        class="input"
        type="password"
        autocomplete="false"
        placeholder="至少6位密码，区分大小写"
        v-decorator="['rePassword', {rules: [{ required: true, message: '至少6位密码，区分大小写' }, { validator: this.handlePasswordCheck }], validateTrigger: ['change', 'blur']}]"
      ></a-input-password>
    </a-form-item>
    <a-form-item class="ok_btn">
      <a-button type="primary" htmlType="submit" @click.stop.prevent="handleSubmit">确认修改</a-button>
    </a-form-item>
  </a-form>
</template>

<script>
import { sipUpdatePassword } from "@api";

export default {
  data() {
    return {
      form: this.$form.createForm(this),
      state: {
        time: 60,
        smsSendBtn: false,
        passwordLevel: 0,
        passwordLevelChecked: false,
        percent: 10,
        progressColor: "#FF0000",
      },
    };
  },
  methods: {
    removeError() {
      this.form.resetFields();
    },
    handleSubmit() {
      const {
        form: { validateFields },
      } = this;

      validateFields((err, values) => {
        if (!err) {
          sipUpdatePassword(values).then((res) => {
            if (res.success) {
              this.$message.success("修改成功");
              this.$emit("passwordUpdateSuccess");
              this.form.resetFields();
            } else {
              this.$message.error(res.msg);
            }
          });
        }
      });
    },
    handlePasswordCheck(rule, value, callback) {
      const password = this.form.getFieldValue("password");
      if (!(password.length == value.length)) {
        callback(new Error("两次密码不一致"));
      }
      if (value === undefined) {
        callback(new Error("请输入密码"));
      }
      if (value && password && value.trim() !== password.trim()) {
        callback(new Error("两次密码不一致"));
      }
      callback();
    },
    handlePasswordLevel(rule, value, callback) {
      let level = 0;
      // 判断这个字符串中有没有数字
      if (/[0-9]/.test(value)) {
        level++;
      }
      // 判断字符串中有没有字母
      if (/[a-zA-Z]/.test(value)) {
        level++;
      }
      // 判断字符串中有没有特殊符号
      if (/[^0-9a-zA-Z_]/.test(value)) {
        level++;
      }

      this.state.passwordLevel = level;
      this.state.percent = level * 30;
      if (level >= 2) {
        if (level >= 3) {
          this.state.percent = 100;
        }
        callback();
      } else {
        if (level === 0) {
          this.state.percent = 10;
        }
        callback(new Error("密码强度不够"));
      }
    },
  },
  watch: {
    "state.passwordLevel"(val) {
      //  // console.log(val)
    },
  },
};
</script>
<style lang="less" scoped>
.input {
  width: 280px;
  height: 32px;
}
.ok_btn {
  margin-left: 130px;
}
</style>
