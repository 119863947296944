var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-form',{attrs:{"form":_vm.form,"hideRequiredMark":true}},[_c('a-form-item',{attrs:{"label":"支付宝账号","labelCol":{ lg: { span: 2 }, sm: { span: 2 } },"wrapperCol":{ lg: { span: 12 }, sm: { span: 12 } }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'alipay',
        {
          rules: [
            {
              required: true,
              pattern: /^(?:1[3-9]\d{9}|[a-zA-Z\d._-]*\@[a-zA-Z\d.-]{1,10}\.[a-zA-Z\d]{1,20})$/,
              message: '请输入正确的支付宝账号'
            }
          ],
          validateTrigger: 'change'
        }
      ]),expression:"[\n        'alipay',\n        {\n          rules: [\n            {\n              required: true,\n              pattern: /^(?:1[3-9]\\d{9}|[a-zA-Z\\d._-]*\\@[a-zA-Z\\d.-]{1,10}\\.[a-zA-Z\\d]{1,20})$/,\n              message: '请输入正确的支付宝账号'\n            }\n          ],\n          validateTrigger: 'change'\n        }\n      ]"}],staticClass:"input",attrs:{"type":"text","autocomplete":"false","placeholder":"请输入支付宝账号"}})],1),_c('a-form-item',{attrs:{"label":"真实姓名","labelCol":{ lg: { span: 2 }, sm: { span: 2 } },"wrapperCol":{ lg: { span: 12 }, sm: { span: 12 } }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'realName',
        {
          rules: [{ required: true, message: '请输入真实姓名' }],
          validateTrigger: 'change'
        }
      ]),expression:"[\n        'realName',\n        {\n          rules: [{ required: true, message: '请输入真实姓名' }],\n          validateTrigger: 'change'\n        }\n      ]"}],staticClass:"input",attrs:{"type":"text","autocomplete":"false","placeholder":"真实姓名"}})],1),_c('a-form-item',{staticClass:"ok_btn"},[_c('a-button',{attrs:{"type":"primary","htmlType":"submit"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_vm._v("确认修改")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }